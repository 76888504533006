.cookieWarning {
    position: fixed;
    height: fit-content;
    background-color: #999;
    bottom: 10px;
    left: 225px;
    right: 225px;
    border-radius: 10px;
    padding: 20px;
}

.cookieWarning h2 {
    margin: 0;
    padding: 0;
    vertical-align: middle;
}

.cookieWarning button {
    margin-left: 25px;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: rgb(64, 184, 64);
    font-size: 15px;
}