.d-inline-block {
    display: inline-block;
}

.m-0 {
    margin: 0;
}

.game {
    position: relative;
    float: left;
    width: calc(100% - 251px);
    height: 100vh;
    background-color:#AAA;
}