.d-inline-block {
    display: inline-block;
} 

.selectorButton {
    position: relative;
    height: 40px;
    width: 40px;
    padding: 10px;
    border-radius: 5px;
    font-size: 35px;
    vertical-align: 25px;
    line-height: 0.5em;
}

.selectorIcon {
    margin: 10px 20px;
    vertical-align: 20px;
    color: white;
}

.mt-10 {
    margin-top: 10px;
}

.h-fit {
    height: fit-content;
}