body {
  background-color: #AAA;
  margin: 0;
}

h1 {
  margin: 0;
}

input {
  outline: none;
  padding: 0;
}

button {
  border: none;
  padding: 0;
}

button:focus {
  outline: none;
}

.color-red {
  color: rgb(223, 4, 4);
}

.color-orange {
  color: orange;
}

.color-yellow {
  color: yellow;
}

.color-green {
  color: rgb(80, 214, 80);
}

.color-blue {
  color: rgb(2, 135, 224);
}

.color-purple {
  color: purple;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.color-magenta {
  color: rgb(187, 41, 187);
}

.color-cyan {
  color: rgb(29, 165, 165);
}

.btn:hover {
  background-color: #BBB;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }