.fourofourContainer {
    position: relative;
    background-color: #AAA;
    height: 100vh;
    width: 100%;
}

.fourofour {
    border: 2px solid #000;
    border-radius: 10px;
    padding: 15px 30px;
    background-color: #888;
    text-align: center;
}

.fourofour h1 {
    margin-bottom: 15px;
}

.fourofour button {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
}

.centered-40 {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
}