@import url('https://fonts.googleapis.com/css2?family=Grandstander:wght@600&display=swap');

.roomCode {
    position: relative;
    color: black;
    padding: 0;
    border: none;
    margin: 0;
    height: 40px;
    width: 80%;
    font-family: 'Grandstander', cursive;
    font-weight: 600;
    font-size: 40px;
    line-height: 3rem;
    background-color: transparent;
}

.roomCode:focus {
    outline: none;
}

.roomCode:hover {
    color: red;
}

.d-inline {
    display: inline;
}