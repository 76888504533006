@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Righteous&display=swap');

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.overflow-hidden {
    overflow: hidden;
}

.d-block {
    display: block;
}

.d-inlineblock {
    display: inline-block;
}

.bg-red {
    background-color: red;
}

.bg-orange {
    background-color: orange;
}

.bg-yellow {
    background-color: yellow;
}

.bg-green {
    background-color: green;
}

.bg-blue {
    background-color: blue;
}

.bg-purple {
    background-color: purple;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100vh;
}

.h-70 {
    height: 70%;
}

.h-50 {
    height: 50%;
}

.h-30 {
    height: 30%;
}

.h-100x {
    height: 100%;
}

.mr-auto {
    margin-left: auto;
}

.ml-1 {
    margin-left: 1%;
}

.mr-auto {
    margin-right: auto;
}

.mr-1 {
    margin-right: 1%;
}

.mt-n5 {
    margin-top: 0.5%;
}

.center {
    text-align: center;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.transform-30 {
    top: 30%;
    transform: translateY(-30%);
}

.transform-50 {
    top: 50%;
    transform: translateY(-50%);
}

.section-10 {
    width: 100%;
    height: 10%;
}

.section-90 {
    width: 100%;
    height: 90%;
}

.transparent-btn {
    background-color: rgb(0, 0, 0, 0);
}

.hitlerSidebar {
    width: 17%;
}

.hitlerCenter {
    width: 66%;
}

.img {
    width: 100%;
    height: 100%;
}

.drawPile {
    max-width: 70%;
}

.hitlerCard {
    max-width: 95%;
    max-height: 55%;
    transform: translateY(20%);
}

.hitlerBoard {
    max-width: 100%;
    max-height: 95%;
    padding-top: 1%;
}

.hitlerGov {
    max-width: 40%;
    max-height: 90%;
}

.hitlerTimer {
    font-size: 10vh;
}

.hitlerDead {
    font-size: 10vh;
}

.hitlerVote {
    background-color: #EEE;
    width: fit-content;
    padding: 10px;
    max-height: fit-content;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.hitlerVote h3 {
    margin-top: 0;
    margin-bottom: 0;
}

.hitlerVote button {
    margin: 10px;
}

.hitlerVote button:hover {
    border: 2px solid #000;
    border-radius: 5px;
    margin: 8px;
}

.hitlerVote img {
    max-width: 100%;
    max-height: 100%;
}

.hitlerWin {
    background-color: rgba(65, 65, 65, 0.685);
    width: 60%;
    padding: 10px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.hitlerWin h1 {
    margin-top: 1%;
    margin-bottom: 0;
    font-size: 100px;
    font-family: 'Righteous', cursive;
}

.hitlerWin h3 {
    margin-top: 1%;
    margin-bottom: 0;
    font-size: 50px;
    font-family: 'Permanent Marker', cursive;
}

.hitlerWin p {
    margin-bottom: 2%;
    font-size: larger;
}

.hitlerWin button {
    width: 15%;
    padding: 20px 0;
    margin-top: 0;
    margin-bottom: 2%;
    border-radius: 10px;
    background-color: #EEE;
    font-size: 20px;
}

.hitlerWin button:hover {
    background-color: #CCC;
}

.hitlerStartBtn {
    padding: 10px 30px;
    font-size: 20px;
    margin-top: 1%;
    border-radius: 5px;
    background-color: #EEE;
}

.hitlerStartBtn:hover {
    background-color: #CCC;
}

.hitlerPolicy {
    background-color: rgba(238, 238, 238);
    width: fit-content;
    padding: 10px;
    max-height: fit-content;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.hitlerPolicy h3 {
    margin-top: 0;
    font-size: 30px;
}

.hitlerPolicy button {
    margin: 5px 20px;
}

.hitlerPolicy button:hover {
    border: 2px solid #000;
    margin: 3px 18px;
}

.hitlerPolicy img {
    max-width: 100%;
    max-height: 100%;
}

.hitlerChancellor {
    background-color: rgba(238, 238, 238);
    width: fit-content;
    max-width: 55%;
    padding: 10px;
    max-height: fit-content;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.hitlerChancellor i {
    margin-bottom: 10px;
}

.hitlerChancellor button {
    margin: 10px 30px;
    padding: 20px 20px 10px 20px;
    border-radius: 10px;
    background-color: #BBB;
}

.hitlerChancellor p {
    font-size: larger;
}

.hitlerChancellorDisabled {
    opacity: 0.4;
}

.hitlerChancellor button:hover {
    background-color: #CCC;
}

.hitlerExamine {
    margin: 10px;
}

.hitlerInvestigate {
    background-color: rgb(238, 238, 238);
    width: 30%;
    padding: 10px;
    max-height: fit-content;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.hitlerInvestigate h3 {
    margin-top: 0;
    margin-bottom: 1%;
    font-size: 30px;
}

.hitlerInvestigate img {
    max-width: 100%;
    max-height: 100%;
}

#hitlerVeto {
    margin-left: auto;
    margin-right: auto;
    padding: 10px 30px;
    font-size: 20px;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #EEE;
}

#hitlerVeto {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #CCC;
}

#hitlerVeto:hover {
    background-color: #CCC;
    margin-top: 13px;
    margin-bottom: 13px;
}

.electionResultPlayer {
    margin: 10px;
    background-color: #888;
    padding: 10px;
    border-radius: 5px;
}

.winPlayer {
    margin: 10px;
    background-color: #888;
    padding: 10px;
    border-radius: 5px;
}

.winPlayer h4 {
    margin: 0;
    margin-bottom: 2px;
}

.bg-black {
    background-color: #000;
}

.rolePreview {
    position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}