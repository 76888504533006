@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.landingGameContainer {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: contain;
    text-align: center;
}

.centered-40 {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
}

.landingGame {
    border: 2px solid #000;
    padding: 20px 50px;
    border-radius: 20px;
    background-color: #999;
}

.landingGame h1 {
    font-family: 'Anton', sans-serif;
    font-size: 50px;
}

.landingGame p {
    margin-bottom: 10px;
    font-size: 20px;
}

.landingGame input {
    position: relative;
    width: 100%;
    height: 30px;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 2px 5px;
    font-size: 25px;
    text-align: center;
}

.landingGame button {
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;
    font-size: 20px;
    border-radius: 5px;
}