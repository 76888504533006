.container {
    position: absolute;
    width: 100%;
    height: 100vh;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered-30 {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
}

.text-align-center {
    text-align: center;
}

.nameContainer {
    border-radius: 10px;
    border: 2px solid #000;
    padding: 30px;
    background-color: rgb(145, 145, 145);
}

.nameContainer h1 {
    margin-bottom: 20px;
}

.nameInput {
    border-radius: 10px;
    height: 30px;
    padding: 5px;
    width: calc(100% - 15px);
    margin-bottom: 15px;
    font-size: 2vh;
}

.nameEnterButton {
    position: relative;
    padding: 6px 40px;
    border-radius: 5px;
    font-size: 2vh;
    text-align: center;
    background-color: rgb(239, 239, 239);
}