.chat {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    position: relative;
    height: calc(100vh - (210px) - 77px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 4px;
    padding-right: 4px;
}

.chat .css-y1c0xs {
    overflow-x: hidden;
}