.optionSet {
    border-bottom: 1px solid rgb(0, 0, 0);
    padding: 10px;
}

.timerSet label {
    float: right;
}

.timerSet input {
    width: 50px;
    margin-right: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.timerSetButton {
    padding: 3px;
}

.colorSet input {
    background-color: transparent;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    margin: 0;
    margin-right: 10px;
}

.colorSet button {
    padding: 3px;
    vertical-align: 0.4rem;
}