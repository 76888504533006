.inputBar {
    position: relative;
    display: inline-block;
    height: 28px;
    border: 1 solid #000;
    width: calc(85% - 4px);
}

.inputButton {
    position: relative;
    display: inline-block;
    height: 30px;
    width: 15%;
    background-color: white;
}
