.d-inline {
    display: inline;
}

.d-inline-block {
    display: inline-block;
}

.float-right {
    float: right;
}

.sidebar {
    position: relative;
    float: right;
    height: 100vh;
    width: 250px;
    border-left: 1px solid #000;
}

.light-theme {
    background-color: rgb(200, 200, 200);
}

.dark-theme {
    background-color: rgb(90, 90, 90);
}

.codeDisplay {
    position: relative;
    border-bottom: 1px solid #000;
    height: 40px
}

.mr-10 {
    margin-right: 5px;
}