.d-inline {
    display: inline;
}

p {
    margin: 0;
}

.chatMsg {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.admin-green {
    color: rgb(47, 155, 47); 
}