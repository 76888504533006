.mt-10 {
    margin-top: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.absolute {
    position: absolute;
}

.h-fit {
    height: fit-content;
}

.w-fit {
    width: fit-content;
}

.optionsBtn i {
    color: #000;
}

.optionsBtn:hover i {
    color: #333;
}

.left {
    text-align: left;
}

.optionsContainer {
    background-color: #888;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
}

.serverInput {
    min-width: 250px;
}

.serverChangeBtn {
    margin-left: 5px;
}

.version-info {
    margin-left: 25px;
    vertical-align: top;
    color: #FFF;
}

.setCustomServerBtn {
    padding: 2px;
    border-radius: 4px;
    margin-left: 5px;
}

.customServerContainer {
    margin-top: 5px;
}