.playerListContainer {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    position: relative;
    height: calc(210px);
    padding-left: 4px;
    padding-right: 4px;
}

.playerList {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    height: 97%;
    width: 100%;
}

.theme-button {
    background-color: transparent;
    padding: 3px;
    border-radius: 3px;
}